const apiRoleManagement = {
  // 15.01 分页查询角色
  getPageQuerySystemRole: '/api/AccountManagement/PageQuerySystemRole',
  // 15.05 新增或更新角色
  goAddOrUpdateRole:"\t/api/AccountManagement/AddOrUpdateRole",
  // 15.03 给角色分配权限
  goAssignRolePermission:'/api/AccountManagement/AssignRolePermission',
  // 15.04 移除角色权限
  goCancelRolePermission:'/api/AccountManagement/CancelRolePermission',
  // 15.02 查询角色下的权限
  getRolePermission:'/api/AccountManagement/GetRolePermission'
}

export default apiRoleManagement
