const getters = {
  /**
   * 外部信用评价
   * @param state
   * @return {{}}
   */
  CreditEvaluationInfo: state => {
    try {
      return Object.keys(state.creditEvaluationObject).length === 0
        ? { ...JSON.parse(window.sessionStorage.getItem('USER_Evaluation')) } : state.creditEvaluationObject
    }
    catch {
      return {}
    }
  },
  /**
   * 创新和曝光做法
   * @param state
   * @return {{}}
   */
  getInnovationAndExposureInfo: state => {
    try {
      return state.innovationAndExposureObject
    }
    catch {
      return {}
    }
  }
}

export default getters
