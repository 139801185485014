import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

import user from '@/store/modules/user'
import management from '@/store/modules/management'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 路由对象
    ROUTER_PATH: {
      path: '/',
      name: 'Login',
      title: '登录',
      params: {},
      query: {}
    },
    // 菜单map
    MENU_MAP: {
      Login: {
        path: '/',
        title: '登录'
      },
      NotFound: {
        path: '/not-found',
        title: '页面不存在'
      },
      MainIndex: {
        path: '/mainIndex',
        title: '首页'
      },
      OutputManagement: {
        path: '/mainIndex/outputManagement',
        title: '产值管理'
      },
      ProgressPerformance: {
        path: '/mainIndex/progressPerformance',
        title: '进度履约'
      },
      MainProject: {
        path: '/mainIndex/mainProject',
        title: '重点工程'
      },
      ProcessInspection: {
        path: '/mainIndex/processInspection',
        title: '过程检查'
      },
      MachineryManagement: {
        path: '/mainIndex/machineryManagement',
        title: '过程检查'
      },
      CreditEvaluation: {
        path: '/mainIndex/creditEvaluation',
        title: '外部信用评价'
      },
      CreditEvaluationDetail: {
        path: '/mainIndex/creditEvaluationDetail',
        title: '外部信用评价'
      },
      ReduceEmissions: {
        path: '/mainIndex/reduceEmissions',
        title: '节能环保'
      },
      SiteStandardization: {
        path: '/mainIndex/siteStandardization',
        title: '施工现场标准化'
      },
      SubcontractingManagement: {
        path: '/mainIndex/subcontractingManagement',
        title: '分包管理'
      },
      SouthernEngineering: {
        path: '/mainIndex/southernEngineering',
        title: '南方工程简报'
      },
      CreditChina: {
        path: '/mainIndex/creditChina',
        title: '信用中国'
      },
      ProjectOrganization: {
        path: '/mainIndex/projectOrganization',
        title: '项目群组织'
      },
      ProjectWarning: {
        path: '/mainIndex/projectWarning',
        title: '项目进度履约预警'
      },
      UserManagement: {
        path: '/mainIndex/userManagement',
        title: '用户管理'
      },
      RoleManagement: {
        path: '/mainIndex/roleManagement',
        title: '角色管理'
      }
    },
  },
  mutations: {
    /**
     * 路由跳转
     * @param state {Vuex.state}
     * @param payload {object} 路由别名
     */
    setRouter(state, payload) {
      const { name, params, query } = { ...payload }
      // 禁止跳转名单
      if (['Home', 'PlanManagement'].some(componentName => componentName === name)) {
        return
      }
      const { path } = state.MENU_MAP[name]
      router
        .push({
          path,
          params,
          query
        })
        .catch(() => {
        })
      const options = {
        ...payload
      }
      state.ROUTER_PATH = { ...options }
      window.sessionStorage.setItem('USER_ROUTER_PATH', name)
    },
    /**
     * 保存路由对象参数
     * @param state {Vuex.state}
     * @param route {Object} 路由参数集合对象
     */
    saveRoutePath(state, route) {
      const options = {
        ...route,
        title: state.MENU_MAP[route.name]?.title ?? ''
      }
      state.ROUTER_PATH = { ...options }
    }
  },
  getters: {
    /**
     * 获取当前功能名称
     * @param state
     * @return {{}}
     */
    getRoutePathName: state => {
      try {
        return state.ROUTER_PATH.name === 'Login' ? window.sessionStorage.getItem('USER_ROUTER_PATH')
          : state.ROUTER_PATH.name
      }
      catch {
        return ''
      }
    }
  },
  actions: {},
  modules: {
    user,
    management
  }
})
