import axios from 'axios'
import { Message } from 'element-ui'

/**
 * 创建axios实例
 */
const instance = axios.create({
  // `url` 是用于请求的服务器 URL
  url: '',
  // `method` 是创建请求时使用的方法
  // 默认是 get
  method: 'get',
  // `baseURL` 将自动加在 `url` 前面，除非 `url` 是一个绝对 URL。
  // 它可以通过设置一个 `baseURL` 便于为 axios 实例的方法传递相对 URL
  baseURL: `${window.location.origin}/`,
  // `params` 是即将与请求一起发送的 URL 参数
  // 必须是一个无格式对象(plain object)或 URLSearchParams 对象
  params: {},
  // `data` 是作为请求主体被发送的数据
  // 只适用于这些请求方法 'PUT', 'POST', 和 'PATCH'
  // 在没有设置 `transformRequest` 时，必须是以下类型之一：
  // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
  // - 浏览器专属：FormData, File, Blob
  // - Node 专属： Stream
  data: {},
  // `timeout` 指定请求超时的毫秒数(0 表示无超时时间)
  // 如果请求时长超过 `timeout` 的时间，请求将被中断
  timeout: 0
})

/**
 * 错误信息参数别名
 * @type {{MESSAGE: string, HAS_MESSAGE: string, MESSAGE_TEXT: string}}
 */
const ERROR_MESSAGE_PARAMS = {
  HAS_MESSAGE: 'HasMessage',
  MESSAGE: 'MessageModel',
  MESSAGE_TEXT: 'Message'
}

// CancelToken 队列
const pendingQueue = new Map()

const { CancelToken } = axios

/**
 * 判断请求是否在队列中，如果在就对队列中的该请求执行取消操作
 * @param config
 */
const judgePendingFunc = config => {
  if (pendingQueue.has(`${config.method}->${config.url}`)) {
    pendingQueue.get(`${config.method}->${config.url}`)()
  }
}
/**
 * 删除队列中对应已执行的请求
 * @param config
 */
const removeResolvedFunc = config => {
  if (pendingQueue.has(`${config.method}->${config.url}`)) {
    pendingQueue.delete(`${config.method}->${config.url}`)
  }
}

// 设置request拦截器
instance.interceptors.request.use(
  config =>
    // Do something before request is sent
    config,
  error =>
    // 请求错误时的回调逻辑
    Promise.reject(error)
)

// 设置response 拦截器
instance.interceptors.response.use(
  response => {
    const { data, status } = response
    
    removeResolvedFunc(response.config)
    
    const codeReg = /^20\d+/ // HTTP状态码 200 ~
    if (!codeReg.test(status.toString())) {
      return Promise.reject(data)
    }
    return data
  },
  error => Promise.reject(error.response)
)
// 请求源
const BASE_URL = `${WEB_CONFIG.BASE_URL}`
// 验证自带请求协议（请求源）
const REG_START_WITH_HTTP = /^http.*/

/**
 * 请求方法
 * @param options {object} 请求参数对象
 * @return {Promise<{data: (*|AxiosResponse<any>), success: boolean, message: (*|null)}|{data: {message: (*|string)}, success: boolean}>}
 */
const request = async options => {
  let { url, method, params, data, headers, timeout, noCancelToken } = options
  
  url = url ?? ''
  url = REG_START_WITH_HTTP.test(url) ? url : BASE_URL + url
  method = method ?? 'get'
  params = params ?? {}
  data = data ?? {}
  timeout = timeout ?? 0
  
  //  const { ID: Token, OrganizationID } = store.getters['user/USER_INFO'] ?? {}
  // 获取客户端版本
  const { version: { content: ClientVersion } = {} } = document.getElementsByTagName('meta')
  // 设置自定义请求头参
  headers = {
    ...(headers ?? {}),
    //    Token,
    //    OrganizationID,
    //    ClientVersion,
    //    Platform: 'Web'
  }
  // 设置是否不cancel重复请求
  noCancelToken = noCancelToken ?? false
  
  const requestParams = {
    url,
    method,
    params,
    data,
    headers,
    timeout
  }
  // 默认cancel重复请求
  if (!noCancelToken) {
    // 请求发起之前先检验该请求是否在队列中，如果在就把队列中pending的请求cancel掉
    judgePendingFunc(requestParams)
    // 将pending队列中的请求设置为当前
    requestParams.cancelToken = new CancelToken(cb => {
      pendingQueue.set(`${requestParams.method}->${requestParams.url}`, cb)
    })
  }
  
  let response = {}
  
  try {
    const res = await instance({ ...requestParams })
    response = {
      success: true,
      data: res?.ResultData ?? res,
      message: !res.HasMessage ? res.MessageModel : null
    }
  }
  catch (err) {
    const hasMessage = err?.data?.[ERROR_MESSAGE_PARAMS.HAS_MESSAGE]
    const content = err?.data?.[ERROR_MESSAGE_PARAMS.MESSAGE]
    
    response = {
      data: {
        message: hasMessage ? content?.[ERROR_MESSAGE_PARAMS.MESSAGE_TEXT] : ''
      },
      success: false
    }
    
    // cancel 的请求不报错误提示
    if (typeof err !== 'undefined') {
      if (response?.data?.message !== '') {
        Message.error(response.data.message)
      }
    }
  }
  return response
}

export default request
