const apiOutputManagement= {
  //01.01 项目月度产值导入
  importProjectMonthlyOutputValue:'/api/EngineeringManagement/ImportProjectMonthlyOutputValue',
  // 01.08 月度产值项目列表
  getPageQueryProjectMonthlyOutputValue: '/api/EngineeringManagement/PageQueryProjectMonthlyOutputValue',
  // 01.14 年度产值年月
  getQuerDate:'/api/EngineeringManagement/QueryDate',
  //01.16 月度开累产值编辑
  addOrupdateMonthTotalOutputValue:'/api/EngineeringManagement/AddOrupdateMonthTotalOutputValue',
  //01.17 获取年度产值
  queryYearOutputValueManagement:'/api/EngineeringManagement/QueryYearOutputValueManagement',
  //01.18 年度产值编辑
  addYearPlanOutputValue:'/api/EngineeringManagement/AddYearPlanOutputValue',
  // 01.11 删除项目月度产值
  goDeleteProjectMonthlyOutputValue: '/api/EngineeringManagement/DeleteProjectMonthlyOutputValue'

}

export default apiOutputManagement
