const apiMachineryManagement = {
  // 06.02 大型机械分页查询
  getPageQueryMachineManage: '/api/Machine/PageQueryMachineManage',
  // 06.01 大型机械填报
  goAddMachineManage: '/api/Machine/AddMachineManage',
  // 06.04大型机械更新
  goUpdateMachineManage: '/api/Machine/UpdateMachineManage',
  // 06.05 大型机械查询详情
  getQueryMachineManageDetail: '/api/Machine/QueryMachineManageDetail',
  // 06.03 大型机械删除
  getDeleteMachineManage: '/api/Machine/DeleteMachineManage',
  //06.06 上传大型机械检查通报文件
  uploadCheckReport:'/api/Machine/UploadCheckReport',
  //06.07 分页查询大型机械检查通报文件
  pageQueryCheckReport:'/api/Machine/PageQueryCheckReport',
}

export default apiMachineryManagement
