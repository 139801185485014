const state = {
  // 用户信息
  USER_INFO: {
    ID: -1
  },
  // 用户权限
  USER_PERMISSIONS: ''
}

export default state
