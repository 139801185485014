const getters = {
  /**
   * 用户信息
   * @param state
   * @return {{}}
   */
  USER_INFO: state => {
    try {
      return state.USER_INFO?.Name
        ? state.USER_INFO
        : { ...JSON.parse(window.sessionStorage.getItem('CRCC_USER_INFO')) }
    } catch {
      return {}
    }
  },
  /**
   * 用户权限
   * @param state
   * @return {{}}
   * @constructor
   */
  USER_PERMISSIONS: state => {
    try {
      return state.USER_PERMISSIONS ?? window.sessionStorage.getItem('USER_PERMISSIONS')
    } catch {
      return ''
    }
  },
  /**
   * 用户组信息
   * @param state
   * @return {object}
   * @constructor
   */
  USER_GROUP: state => {
    try {
      return state.USER_GROUP?.Name
        ? state.USER_GROUP
        : { ...JSON.parse(window.sessionStorage.getItem('USER_GROUP')) }
    } catch {
      return {}
    }
  },
  // eslint-disable-next-line no-unused-vars
  hasPermission: state => permission =>
    window.sessionStorage.getItem('USER_PERMISSIONS').includes(permission)
}

export default getters
