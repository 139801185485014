const apiSiteStandardization = {
  // 08.01 上传施工现场标准化手册
  goUploadStandardFile: '/api/Construction/UploadStandardFile',
  // 08.02 查询施工现场标准化手册
  getQueryStandardFile: '/api/Construction/QueryStandardFile',
  // 08.03 上传施工标准化过程检查通报
  goAddNotificationFile: '/api/Construction/AddNotificationFile',
  // 08.04 查询施工标准化过程检查通报
  getPageQueryNotificationFile: '/api/Construction/PageQueryNotificationFile',
  // 08.05 删除施工标准化过程检查通报
  goDeleteNotificationFile: '/api/Construction/DeleteNotificationFile',
  // 08.06 上传创新，曝光做法
  goConstructionStandard: '/api/Construction/AddConstructionStandard',
  // 08.07 分页查询创新，曝光做法
  getPageQueryConstructionStandard: '/api/Construction/PageQueryConstructionStandard',
  // 08.08 删除创新，曝光做法
  goDeleteConstructionStandard: '/api/Construction/DeleteConstructionStandard',
  // 08.09 编辑创新，曝光做法
  goUpdateConstructionStandard: '/api/Construction/UpdateConstructionStandard',
  // 08.12 现场施工标准化文件查询
  getPageQueryConstructionStandardFile:"/api/Construction/PageQueryConstructionStandardFile",
  // 08.11 现场施工标准化文件上传
  getUploadConstructionStandardFile:'/api/Construction/UploadConstructionStandardFile'
}

export default apiSiteStandardization
