const mutations = {
  setCreditEvaluationInfo(state, info) {
    state.creditEvaluationObject = info
    window.sessionStorage.setItem('USER_Evaluation', JSON.stringify({ ...info }))

  },
  setInnovationAndExposureObjectInfo(state, info) {
    state.innovationAndExposureObject = info
  }
}

export default mutations
