const apiCreditEvaluation = {
  // 11.02 信用中国分页查询
  getPageQueryCreditHistory:'/api/CreditHistory/PageQueryCreditHistory',
  // 11.01 信用中国填报
  goAddCreditHistory:'/api/CreditHistory/AddCreditHistory',
  // 11.03 信用中国更新
  goUpdateCreditHistory:'/api/CreditHistory/UpdateCreditHistory',
  // 11.06 信用中国查询详情
  getQueryCreditHistoryDetail:'/api/CreditHistory/QueryCreditHistoryDetail',
  // 11.04 信用中国删除
  goDeleteCreditHistory:'/api/CreditHistory/DeleteCreditHistory'
}

export default apiCreditEvaluation
