import api from '@/http/apis/index'
import request from '@/http/request'

const actions = {
  async handleEmergencyStatus({ commit }) {
    const { success, data } = await request({
      url: api.queryUserProcessFaultCount
    })
    if (success) {
      const { Count: count = 0 } = data
      commit('setEmergencyStatus', count > 0)
    } else {
      commit('setEmergencyStatus', false)
    }
  }
}

export default actions
