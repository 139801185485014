import request from '@/http/request'
import index from '@/http/apis'

const actions = {
  /**
   * 查询并设置用户权限
   * @param dispatch
   * @param commit
   * @param getters
   * @return {Promise<void>}
   */
  async handleUserPermissions({ commit, getters }) {
    const { success, data } = await request({
      url: index.getUserPermissions,
      params: {
        userID: getters.USER_INFO?.ID
      }
    })
    if (success) {
      commit('setUserPermissions', data)
    } else {
      commit('setUserPermissions', [])
    }
  },
  /**
   * 查询设置用户所在分组
   * @param dispatch
   * @param commit
   * @param getters
   * @return {Promise<void>}
   */
  async handleUserGroup({ commit, getters }) {
    const { success, data } = await request({
      url: index.queryUserInGroup,
      params: {
        userID: getters.USER_INFO?.ID
      }
    })
    if (success) {
      commit('setUserGroup', data)
    } else {
      commit('setUserGroup', {})
    }
  }
}

export default actions
