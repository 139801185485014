import state from '@/store/modules/user/state'
import mutations from '@/store/modules/user/mutations'
import getters from '@/store/modules/user/getters'
import actions from '@/store/modules/user/actions'

const user = {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
  modules: {}
}

export default user
