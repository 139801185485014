const apiSubcontractingManagement = {
  // 09.01 分包管理附件查询
  getPageQueryFiles: '/api/SubPackage/PageQueryFiles',
  // 09.02 分包管理附件上传
  goUploadFile: '/api/SubPackage/UploadFile',
  // 09.03 区域战略分包导入
  goImport: '/api/AreaSubcontract/Import',
  // 09.07 区域战略分包分页查询
  getPageQueryAreaSubcontractItem: '/api/AreaSubcontract/PageQueryAreaSubcontractItem',
  // 09.08 主要分包资源导入
  goResourceImport: '/api/SubpackageResource/Import',
  // 09.10 主要分包资源分页查询
  getPageQuerySubpackageResource: '/api/SubpackageResource/PageQuerySubpackageResource'
}

export default apiSubcontractingManagement
