const projectWarning = {
   //17.01 项目进度履约预警导入
  importProjectProgressWarn:'/api/ProjectProgress/ImportProjectProgressWarn',
  //17.02 项目进度履约预警填报
  addProjectProgressWarn:'/api/ProjectProgress/AddProjectProgressWarn',
  //17.03 项目进度履约预警删除
  deleteProjectProgressWarn:'/api/ProjectProgress/DeleteProjectProgressWarn',
  //17.04 项目进度履约预警更新
  updateProjectProgressWarn:'/api/ProjectProgress/UpdateProjectProgressWarn',
  //17.07 项目进度履约分页查询
  pageQueryProjectProgressWarn:'/api/ProjectProgress/PageQueryProjectProgressWarn',
  //17.08 项目进度履约发送推送
  pushMessage:'/api/ProjectProgress/PushMessage',
  //17.09 项目进度履约 查询详情
  pageQueryProjectDetail:'/api/ProjectProgress/PageQueryProjectDetail'
}

export default projectWarning
