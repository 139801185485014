import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/css/common.css'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

Vue.component('ElImageViewer', ElImageViewer)
//下载文件
import '@/plugins/download'

import request from '@/http/request'
import api from '@/http/apis'

Vue.prototype.$http = request
Vue.prototype.$api = api


/**
 * 时间格式化
 * @param time {Date} 时间
 * @param format {string} 时间格式化模板
 * @returns {String} 返回格式化后的时间内容
 */
Vue.prototype.$dateFormat = (time, format) => {
  const t = new Date(time)
  return format.replace(/yyyy|MM|dd|HH|mm|ss/g, str => {
    switch (str) {
      case 'yyyy':
        return t
          .getFullYear()
          .toString()
          .padStart(4, '0')
      case 'MM':
        return (t.getMonth() + 1).toString().padStart(2, '0')
      case 'mm':
        return t
          .getMinutes()
          .toString()
          .padStart(2, '0')
      case 'dd':
        return t
          .getDate()
          .toString()
          .padStart(2, '0')
      case 'HH':
        return t
          .getHours()
          .toString()
          .padStart(2, '0')
      case 'ss':
        return t
          .getSeconds()
          .toString()
          .padStart(2, '0')
      default: {
        //
      }
    }
  })
}

/**
 * 下载文件
 * @param url 链接
 * @param type 类型 是否包含当前服务器地址
 */
Vue.prototype.$downloadFileChange = (url, type) => {
  //  window.downloadFile(`${WEB_CONFIG.BASE_URL}${url}`)
  window.open(`${WEB_CONFIG.BASE_URL}${url}`, '_blank')
}

Vue.config.productionTip = false

Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
