const apiCreditEvaluation = {
  // 07.03 外部信用评价查询（XX年上/下半年）
  getPageQueryCreditEvaluation: '/api/Credit/PageQueryCreditEvaluation',
  // 07.01 外部信用评价导入
  goImportCreditEvaluation: '/api/Credit/ImportCreditEvaluation',
  // 07.02 外部信用评价统计
  getCreditEvaluationStatistics: '/api/Credit/CreditEvaluationStatistics',
  // 07.06 外部信用评价填报
  goAddCreditEvaluation: '/api/Credit/AddCreditEvaluation',
  // 07.05 外部信用评价更新
  goUpdateCreditEvaluation: '/api/Credit/UpdateCreditEvaluation',
  // 07.07 外部信用评价详情
  getQueryCreditEvaluationDetail: '/api/Credit/QueryCreditEvaluationDetail',
  // 07.04 外部信用评价删除
  goDeleteCreditEvaluation: '/api/Credit/DeleteCreditEvaluation',
  //  07.08 外部信用评价类型查询
  getQueryCategory: '/api/Credit/QueryCategory'
}

export default apiCreditEvaluation
