const apiSouthernEngineering = {
  // 10.02 南方工程简报分页查询
  getPageQueryProjectNews:'/api/ProjectNews/PageQueryProjectNews',
  // 10.01 南方工程简报填报
  goAddProjectNews:'/api/ProjectNews/AddProjectNews',
  // 10.03 南方工程简报更新
  goUpdateProjectNews:'/api/ProjectNews/UpdateProjectNews',
  // 10.05 南方工程简报详情
  getQueryProjectNewsDetail:'/api/ProjectNews/QueryProjectNewsDetail',
  // 10.04 南方工程简报删除
  goDeleteProjectNews:"/api/ProjectNews/DeleteProjectNews"

}

export default apiSouthernEngineering
