const apiUserManagement = {
  // 14.02 分页查询用户
  getPageQueryActivatedUser: '/api/User/PageQueryActivatedUser',
  // 14.01 添加或更新用户
  goAddOrUpdateUser: '/api/User/AddOrUpdateUser',
  // 14.03 修改用户密码
  goChangeUserPassword: '/api/User/ChangeUserPassword',
  // 14.04 查询角色信息（根据用户ID）
  getSystemRolesByUserID: '/api/AccountManagement/GetSystemRolesByUserID',
  // 14.05 绑定用户与角色
  goAssignUserRole: '/api/AccountManagement/AssignUserRole',
  // 14.06 解绑用户与角色
  goCancelUserRole: '/api/AccountManagement/CancelUserRole'
}

export default apiUserManagement
