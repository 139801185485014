const apiProjectOrganization = {
  // 13.09 项目群导入
  goImportProjectCluster: '/api/ProjectCluster/ImportProjectCluster',
  // 13.05 分页查询项目群
  getPageQueryProjectCluster: '/api/ProjectCluster/PageQueryProjectCluster',
  // 13.01 添加项目群
  goAddProjectCluster: '/api/ProjectCluster/AddProjectCluster',
  // 13.02 更新项目群
  goUpdateProjectCluster: '/api/ProjectCluster/UpdateProjectCluster',
  //  13.04 查询项目群详情
  getQueryProjectCluster: '/api/ProjectCluster/QueryProjectCluster',
  // 13.03 删除项目群
  goDeleteProjectCluster:'/api/ProjectCluster/DeleteProjectCluster',
  // 13.08 查询可绑定项目
  getPageQueryProjectList:'/api/ProjectCluster/PageQueryProjectList',
  // 13.06 项目群绑定项目
  goBindProjectAndCluster:'/api/ProjectCluster/BindProjectAndCluster',
  // 13.07 解绑项目群项目
  goUnBindProjectAndCluster:'/api/ProjectCluster/UnBindProjectAndCluster',
  // 13.10 项目群 获取导入模板
  goDownloadTemplatePath:'/api/ProjectCluster/DownloadTemplatePath'
}

export default apiProjectOrganization
