import apiLogin from '@/http/apis/modules/login'
import apiProgressPerformance from '@/http/apis/modules/progressPerformance'
import apiOutputManagement from '@/http/apis/modules/outputManagement'
import apiMainProject from '@/http/apis/modules/mainProject'
import apiProcessInspection from '@/http/apis/modules/processInspection'
import apiMachineryManagement from '@/http/apis/modules/machineryManagement'
import apiCreditEvaluation from '@/http/apis/modules/creditEvaluation'
import apiSouthernEngineering from '@/http/apis/modules/southernEngineering'
import apiCreditChina from '@/http/apis/modules/creditChina'
import apiProjectOrganization from '@/http/apis/modules/projectOrganization'
import apiSubcontractingManagement from '@/http/apis/modules/subcontractingManagement'
import apiUserManagement from '@/http/apis/modules/userManagement'
import apiRoleManagement from '@/http/apis/modules/roleManagement'
import apiSiteStandardization from '@/http/apis/modules/siteStandardization'
import projectWarning from '@/http/apis/modules/projectWarning'
import energyConservation from '@/http/apis/modules/energyConservation'

const api = {
  // 登录
  ...apiLogin,
  ...apiProgressPerformance,
  ...apiOutputManagement,
  ...apiMainProject,
  ...apiProcessInspection,
  ...apiMachineryManagement,
  ...apiCreditEvaluation,
  ...apiSouthernEngineering,
  ...apiCreditChina,
  ...apiProjectOrganization,
  ...apiSubcontractingManagement,
  ...apiUserManagement,
  ...apiRoleManagement,
  ...apiSiteStandardization,
  ...projectWarning,
  ...energyConservation,
  // 16.01 文档删除
  goDeleteDocumentVersionsByVersionIDs: '/api/DocManager/DeleteDocumentVersionsByVersionIDs'
}

export default api
