const mutations = {
  /**
   * 设置用户信息
   * @param state
   * @param info {object} 用户信息
   */
  setUserInfo(state, info) {
    state.USER_INFO = { ...info }
    window.sessionStorage.setItem('CRCC_USER_INFO', JSON.stringify({ ...info }))
  },
  /**
   * 设置用户权限
   * @param state
   * @param permissions
   */
  setUserPermissions(state, permissions) {
    state.USER_PERMISSIONS = permissions
    window.sessionStorage.setItem('USER_PERMISSIONS', permissions)
  },
  /**
   * 设置用户所在分组信息
   * @param state
   * @param info
   */
  setUserGroup(state, info) {
    state.USER_GROUP = { ...info }
    window.sessionStorage.setItem('USER_GROUP', JSON.stringify({ ...info }))
  }
}

export default mutations
