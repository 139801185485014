
// 接口文档地址 http://47.110.15.13:8020/pages/viewpage.action?pageId=24739942
const energyConservation = {
  //05.06 年度指标创建/更新
  addOrUpdateEnergyTarget:'/api/Energy/AddOrUpdateEnergyTarget',
  //05.07 查询年度指标
  queryYearEnergyTarget:'/api/Energy/QueryYearEnergyTarget',
  //05.08 分页查询年度指标
  pageQueryYearEnergyTarget:'/api/Energy/PageQueryYearEnergyTarget',
  //05.09 季度节能环保工作创建/更新
  addOrUpdateQuarterEnvProWork:'/api/Energy/AddOrUpdateQuarterEnvProWork',
  //05.10 季度节能环保工作查询
  queryQuarterEnvProWork:'/api/Energy/QueryQuarterEnvProWork',
  //05.11 季度节能环保工作分页查询
  pageQueryQuarterEnvProWork:'/api/Energy/PageQueryQuarterEnvProWork',
  //05.12 过程管控检查结果新增/更新
  addOrUpdateProcessControlCheckResult:'/api/Energy/AddOrUpdateProcessControlCheckResult',
  //05.13 过程管控检查结果检查项新增/更新
  addOrUpdateProcessControlCheckResultItem:'/api/Energy/AddOrUpdateProcessControlCheckResultItem',
  //05.14 过程管控检查结果详情查询
  queryProcessControlCheckResult:'/api/Energy/QueryProcessControlCheckResult',
  //05.15 过程管控检查结果检查项查询
  queryProcessControlCheckResultItems:'/api/Energy/QueryProcessControlCheckResultItems',
  //05.16 过程管控检查结果分页查询
  pageQueryProcessControlCheckResult:'/api/Energy/PageQueryProcessControlCheckResult',
  //05.17 过程管控检查结果删除
  deleteProcessControlCheckResult:'/api/Energy/DeleteProcessControlCheckResult',
  //05.18 过程管控检查结果检查项删除
  deleteProcessControlCheckResultItem:'/api/Energy/DeleteProcessControlCheckResultItem',
}

export default energyConservation
