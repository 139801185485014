const apiProcessInspection = {
  // 04.02 过程检查分页查询
  getPageQueryProcessCheck: '/api/ProcessCheck/PageQueryProcessCheck',
  // 04.01 过程检查填报
  goAddProcessCheck: '/api/ProcessCheck/AddProcessCheck',
  // 04.04 过程检查更新
  goUpdateProcessCheck: '/api/ProcessCheck/UpdateProcessCheck',
  // 04.03 过程检查详情
  getQueryProcessCheck: '/api/ProcessCheck/QueryProcessCheck',
  // 04.05 过程检查删除
  getDeleteProcessCheck: '/api/ProcessCheck/DeleteProcessCheck'
}

export default apiProcessInspection
