const apiProgressPerformance = {
  // 02.04 项目列表
  getPageQueryProgressPerformance: '/api/ProgressPerformance/PageQueryProgressPerformance',
  // 02.01 进度履约导入
  goImportProgressPerformance: '/api/ProgressPerformance/ImportProgressPerformance',
  // 02.06 进度履约填报
  goAddProgressPerformance: '/api/ProgressPerformance/AddProgressPerformance',
  //  02.08 进度履约删除
  goDeleteProgressPerformance: '/api/ProgressPerformance/DeleteProgressPerformance',
  //  02.07 进度履约详情
  getQueryDetailByID: '/api/ProgressPerformance/QueryDetailByID',
  // 02.05 进度履约更新
  goUpdateProgressPerformance: '/api/ProgressPerformance/UpdateProgressPerformance',
  //  02.10 查询最大导入月份
  getQueryDefaultDate: '/api/ProgressPerformance/QueryDefaultDate',
  // 18.01 项目状态导入
  goImportProjectStatus: '/api/ProjectStatus/ImportProjectStatus',
  // 18.02 项目状态查询
  getQueryProjectStatus: '/api/ProjectStatus/QueryProjectStatus',
  // 18.04 分页查询项目状态信息
  getPageQueryProjectStatus: '/api/ProjectStatus/PageQueryProjectStatus'
}

export default apiProgressPerformance
