import state from '@/store/modules/management/state'
import mutations from '@/store/modules/management/mutations'
import actions from '@/store/modules/management/actions'
import getters from '@/store/modules/management/getters'

const management = {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
  modules: {}
}

export default management
