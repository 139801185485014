import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Login.vue')
  },
  {
    path: '/mainIndex',
    name: 'MainIndex',
    component: () => import(/* webpackChunkName: "about" */ '@/views/mainIndex/mainIndex.vue'),
    children: [
      {
        path: 'outputManagement',
        name: 'OutputManagement',
        component: () => import(/* webpackChunkName: "about" */ '@/views/outputManagement/outputManagement.vue')
      },
      {
        path: 'progressPerformance',
        name: 'ProgressPerformance',
        component: () => import(/* webpackChunkName: "about" */ '@/views/progressPerformance/progressPerformance.vue')
      },
      {
        path: 'mainProject',
        name: 'MainProject',
        component: () => import(/* webpackChunkName: "about" */ '@/views/mainProject/mainProject.vue')
      },
      {
        path: 'processInspection',
        name: 'ProcessInspection',
        component: () => import(/* webpackChunkName: "about" */ '@/views/processInspection/processInspection.vue')
      },
      {
        path: 'machineryManagement',
        name: 'MachineryManagement',
        component: () => import(/* webpackChunkName: "about" */ '@/views/machineryManagement/machineryManagement.vue')
      },
      {
        path: 'creditEvaluation',
        name: 'CreditEvaluation',
        component: () => import(/* webpackChunkName: "about" */ '@/views/creditEvaluation/creditEvaluation.vue')
      },
      {
        path: 'creditEvaluationDetail',
        name: 'CreditEvaluationDetail',
        component: () => import(/* webpackChunkName: "about" */ '@/views/creditEvaluation/creditEvaluationDetail.vue')
      },
      {
        path: 'reduceEmissions',
        name: 'ReduceEmissions',
        component: () => import(/* webpackChunkName: "about" */ '@/views/reduceEmissions/reduceEmissions.vue')
      },
      {
        path: 'siteStandardization',
        name: 'SiteStandardization',
        component: () => import(/* webpackChunkName: "about" */ '@/views/siteStandardization/siteStandardization.vue')
      },
      {
        path: 'subcontractingManagement',
        name: 'SubcontractingManagement',
        component: () => import(/* webpackChunkName: "about" */ '@/views/subcontractingManagement/subcontractingManagement.vue')
      },
      {
        path: 'southernEngineering',
        name: 'SouthernEngineering',
        component: () => import(/* webpackChunkName: "about" */ '@/views/southernEngineering/southernEngineering.vue')
      },
      {
        path: 'creditChina',
        name: 'CreditChina',
        component: () => import(/* webpackChunkName: "about" */ '@/views/creditChina/creditChina.vue')
      },
      {
        path: 'projectOrganization',
        name: 'ProjectOrganization',
        component: () => import(/* webpackChunkName: "about" */ '@/views/projectOrganization/projectOrganization.vue')
      },
      {
        path: 'projectWarning',
        name: 'ProjectWarning',
        component: () => import(/* webpackChunkName: "about" */ '@/views/projectWarning/projectWarning.vue')
      },
      {
        path: 'userManagement',
        name: 'UserManagement',
        component: () => import(/* webpackChunkName: "about" */ '@/views/userManagement/userManagement.vue')
      },
      {
        path: 'roleManagement',
        name: 'RoleManagement',
        component: () => import(/* webpackChunkName: "about" */ '@/views/roleManagement/roleManagement.vue')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, _from, next) => {
  const { path, params, query, name } = to
  // 通过获取本地有没有登录用户ID判断是否已登录
  const hasLogin = JSON.parse(window.sessionStorage.getItem('CRCC_USER_INFO'))?.ID
  //  // 完全免登录路由地址
  //  const withoutLoginPathList = [
  //    /* app流程图 */ '/emergency-management/workflow-app',
  //    /* app下载 */ '/app-download',
  //    /* app进程看板 */ '/em',
  //    /* 设备管理地图 */ '/subway',
  //    /* 模型管理 */ '/model-management'
  //  ]

  // 无效路由
  if (path === '/not-found') {
    next(false)
  }
    //  // 免登录
    //  else if (withoutLoginPathList.some(p => p === path)) {
    //    next()
    //  }
  // 登录
  else if (name === 'Login') {
    next()
  }
  // 未登录
  else if (!hasLogin) {
    let nextRouteParams = { path: '/' }
    //    if (to.fullPath !== '/') {
    //      const [TO_ROUTE_PATH = ''] = to.fullPath?.split('?') ?? []
    //      if (ROUTE_PATH.find(p => p === TO_ROUTE_PATH)) {
    //        nextRouteParams = {
    //          ...nextRouteParams,
    //          query: {
    //            redirect: to.fullPath
    //          }
    //        }
    //      }
    //      else {
    //        nextRouteParams = {
    //          name: 'NotFound'
    //        }
    //      }
    //    }
    next(nextRouteParams)
  }
  // 已登录正常跳转
  else {
    // 保存当前路由信息
    store.commit('saveRoutePath', { path, params, query, name })
    next()
  }
})

export default router
